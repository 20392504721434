import React from "react";
import code from "../assets/code2.png";
import { HiArrowNarrowRight } from "react-icons/hi";
import profile from "../assets/work2.jpg";
import { Link } from "react-scroll";

const Works2 = () => {
  return (
    <div name="work2" className="h-screen w-full bg-[#274a4d]">
      <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
        <div className="flex flex-col justify-center h-full">
          <h1 className="text-5xl sm:text-7xs font-bold text-white">
            Fixed internet connectivity issues
          </h1>
          <p className="text-white py-4 max-w-md">
            I helped to troubleshoot and fix connectivity issues a friend was
            having with their internet. First I checked to see if their internet
            would work with an ethernet cable. This turned out to be true and so
            I tried to reset their modem and device. This didnt change anything
            so i tried to connect on other devices in the house. Others could
            connect so I knew it was just the one device that was having issues.
            After double checking that the device was trying to connect to the
            correct network I reset the devices network settings to their
            factory defaults and reset both devices. This fixed the issue after
            trying to reconnect.{" "}
          </p>
          <ul className="text-purple-400 list-decimal ml-8 p-4">
            <li>
              To start troubleshooting with checking whether the connection
              problem is specific to the type of connection
            </li>
            <li>
              How to properly reset a modem and device to check whether a clean
              setup would fix things
            </li>
            <li>
              Troubleshooting method of trying to connect via multiple devices
              to test whether it is specific to one or multiple devices{" "}
            </li>
            <li>
              How to reset the device network settings to factory defaults{" "}
            </li>
          </ul>
          <div>
            <Link
              to="skills"
              smooth
              duration={500}
              className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-emerald-500 to-lime-500 cursor-pointer"
            >
              NEXT
              <span className="group-hover:rotate-90 duration-300">
                <HiArrowNarrowRight size={25} className="ml-3" />
              </span>
            </Link>
          </div>
        </div>
        <div>
          <img
            src={profile}
            alt="my profile"
            className="rounded-2xl mx-auto w-2/3 "
          />
        </div>
      </div>
    </div>
  );
};
export default Works2;

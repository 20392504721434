import React from "react";
import code from "../assets/code2.png";
import { HiArrowNarrowRight } from "react-icons/hi";
import profile from "../assets/work1.jpg";
import { Link } from "react-scroll";

const Works1 = () => {
  return (
    <div name="work1" className="h-screen w-full bg-[#274a4d]">
      <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
        <div>
          <img
            src={profile}
            alt="my profile"
            className="rounded-2xl  w-3/4 m-10"
          />
        </div>
        <div className="flex flex-col justify-center h-full ml-1">
          <h1 className="text-5xl sm:text-7xs font-bold text-white">
            Set up network printing
          </h1>
          <p className="text-white py-4 max-w-md">
            My neighbour was having trouble setting up their printer to work
            using WAN. They could only print using a usb connection to the
            printer. To fix this I went into their printers WAN settings and put
            in the password for their modem. This connected the printer to their
            network which meant it could be accessed in the printer & scanner
            settings on windows.{" "}
          </p>
          <ul className="text-purple-400 list-decimal ml-8 p-4">
            <li>How to access a printers network settings</li>
            <li>Input network details to connect printer wirelessly</li>
            <li>
              How to connect to the printer through windows printing settings{" "}
            </li>
          </ul>
          <div>
            <Link
              to="work2"
              smooth
              duration={500}
              className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-emerald-500 to-lime-500 cursor-pointer"
            >
              NEXT
              <span className="group-hover:rotate-90 duration-300">
                <HiArrowNarrowRight size={25} className="ml-3" />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Works1;

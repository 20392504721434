import React from "react";
import code from "../assets/code2.png";
import { HiArrowNarrowRight } from "react-icons/hi";
import profile from "../assets/work.jpg";
import { Link } from "react-scroll";

const Works = () => {
  return (
    <div name="work" className="h-screen w-full bg-[#274a4d]">
      <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-4 md:flex-row">
        <div className="flex flex-col justify-center h-full">
          <h1 className="text-5xl sm:text-7xs font-bold text-white">
            CSGO Server hosted on AWS
          </h1>
          <p className="text-white py-4 max-w-md">
            In this project I used the free tier Linux 2 AMI package to host a
            server that can be used in the game Counter Strike Global Offensive.
            As this was created as practice and didn't need to host a large
            number of players, I chose to use the t2.micro instance type which
            gives users 1 GiB Memory and 1 vCPU to work with for free. This was
            more than enough for my requirements.{" "}
          </p>
          <ul className="text-purple-400 list-decimal ml-8 p-4">
            <li>
              How to set up servers with the correct specifications on AWS.
            </li>
            <li>
              Creating separate users and attach different authorisations.
              Install and unzip files to give access to servers.
            </li>
            <li>Write scripts in cmd to run on the startup of a server.</li>
            <li>Connect to the game server with a specific token.</li>
            <li>Run a server and connect in the game UI.</li>
          </ul>
          <div>
            <Link
              to="work1"
              smooth
              duration={500}
              className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-emerald-500 to-lime-500 cursor-pointer"
            >
              NEXT
              <span className="group-hover:rotate-90 duration-300">
                <HiArrowNarrowRight size={25} className="ml-3" />
              </span>
            </Link>
          </div>
        </div>
        <div>
          <img
            src={profile}
            alt="my profile"
            className="rounded-1xl m-10 w-3/3 "
          />
        </div>
      </div>
    </div>
  );
};
export default Works;

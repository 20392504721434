import React from "react";

const Contact = () => {
  return (
    <div className="bg-[#274a4d]  text-center pb-9">
      <h2 className="text-4xl sm:text-5xl font-bold text-white">Contact Me</h2>
      <p className="text-white py-4 p-4 text-center">
        Email: jakobripley1@gmail.com
      </p>
      <p className="text-white  text-center">Number: 07483800572</p>
    </div>
  );
};
export default Contact;
